var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "q-form",
        { ref: "editForm" },
        [
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "순회점검 기본정보" },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "순회명",
                        name: "patrolName",
                      },
                      model: {
                        value: _vm.patrol.patrolName,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "patrolName", $$v)
                        },
                        expression: "patrol.patrolName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-datepicker", {
                      attrs: {
                        required: "",
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "순회일",
                        type: "date",
                        name: "patrolDate",
                      },
                      model: {
                        value: _vm.patrol.patrolDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "patrolDate", $$v)
                        },
                        expression: "patrol.patrolDate",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-text", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "순회장소",
                        name: "sopMapId",
                      },
                      model: {
                        value: _vm.patrol.sopMapId,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "sopMapId", $$v)
                        },
                        expression: "patrol.sopMapId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-plant", {
                      attrs: {
                        required: "",
                        disabled: _vm.disabled,
                        editable: _vm.editable,
                        type: "edit",
                        name: "plantCd",
                      },
                      on: { datachange: _vm.datachange },
                      model: {
                        value: _vm.patrol.plantCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "plantCd", $$v)
                        },
                        expression: "patrol.plantCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-dept", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "부서",
                        name: "vendorCd",
                      },
                      model: {
                        value: _vm.patrol.vendorCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "vendorCd", $$v)
                        },
                        expression: "patrol.vendorCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3",
                  },
                  [
                    _c("c-process", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        label: "공정",
                        name: "processCd",
                      },
                      model: {
                        value: _vm.patrol.processCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "processCd", $$v)
                        },
                        expression: "patrol.processCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-construnction", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        name: "sopConstructionId",
                      },
                      model: {
                        value: _vm.patrol.sopConstructionId,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "sopConstructionId", $$v)
                        },
                        expression: "patrol.sopConstructionId",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6",
                  },
                  [
                    _c("c-risk-assess", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        name: "ramRiskAssessmentPlanId",
                      },
                      model: {
                        value: _vm.patrol.ramRiskAssessmentPlanId,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "ramRiskAssessmentPlanId", $$v)
                        },
                        expression: "patrol.ramRiskAssessmentPlanId",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c(
            "c-table",
            {
              ref: "grid",
              attrs: {
                title: "항목별 결과 목록",
                isTitle: true,
                columns: _vm.grid.columns,
                gridHeightAuto: true,
                data: _vm.patrol.results,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                noDataLabel: "항목별 결과 추가하세요.",
                hideBottom: true,
                editable: _vm.editable && !_vm.disabled,
                rowKey: "saiPatrolResultId",
                selection: "multiple",
              },
              scopedSlots: _vm._u([
                {
                  key: "customArea",
                  fn: function ({ props, col }) {
                    return [
                      col.name === "customCol"
                        ? [
                            _c(_vm.imprComponent, {
                              tag: "component",
                              attrs: {
                                col: col,
                                props: props,
                                inputEditable: _vm.editable && !_vm.disabled,
                                isImmShow: true,
                                requestContentsCols: _vm.requestContentsCols,
                                tableKey: "saiPatrolResultId",
                                ibmTaskTypeCd: "ITT0000065",
                                ibmTaskUnderTypeCd: "ITTU000105",
                                acceptBtnShow: true,
                              },
                              on: { imprChange: _vm.imprChange },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "customArea",
                                    fn: function ({ props }) {
                                      return [
                                        _c("div", { staticClass: "col-6" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "mini-dailog-title",
                                            },
                                            [_vm._v("비고")]
                                          ),
                                        ]),
                                        _c(
                                          "div",
                                          { staticClass: "col-12" },
                                          [
                                            _c("c-text", {
                                              attrs: {
                                                editable:
                                                  _vm.editable && !_vm.disabled,
                                                label: "",
                                                name: "patrolContents",
                                              },
                                              model: {
                                                value: props.row.patrolContents,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    props.row,
                                                    "patrolContents",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "props.row.patrolContents",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ]
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "추가",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.addResult },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.patrol.results.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "제외",
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeResult },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _c(
            "c-card",
            {
              staticClass: "cardClassDetailForm",
              attrs: { title: "순회결과 정보", collapsed: true },
            },
            [
              _c("template", { slot: "card-detail" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4",
                  },
                  [
                    _c("c-select", {
                      attrs: {
                        codeGroupCd: "PATROL_RESULT_CD",
                        editable: _vm.editable,
                        type: "edit",
                        disabled: _vm.disabled,
                        itemText: "codeName",
                        itemValue: "code",
                        name: "patrolResultCd",
                        label: "순회결과",
                      },
                      model: {
                        value: _vm.patrol.patrolResultCd,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "patrolResultCd", $$v)
                        },
                        expression: "patrol.patrolResultCd",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                  },
                  [
                    _c("c-textarea", {
                      attrs: {
                        editable: _vm.editable,
                        disabled: _vm.disabled,
                        rows: 5,
                        label: "순회결과요약",
                        name: "patrolResultSummary",
                      },
                      model: {
                        value: _vm.patrol.patrolResultSummary,
                        callback: function ($$v) {
                          _vm.$set(_vm.patrol, "patrolResultSummary", $$v)
                        },
                        expression: "patrol.patrolResultSummary",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            2
          ),
          _c("c-upload", {
            attrs: {
              label: "관련파일",
              attachInfo: _vm.attachInfo,
              editable: _vm.editable && !_vm.disabled,
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "popup-bottom-bar" }, [
        _c(
          "div",
          { staticClass: "popup-bottom-bar-btngroup" },
          [
            _c(
              "q-btn-group",
              { attrs: { outline: "" } },
              [
                !_vm.popupParam.isApprContent
                  ? _c("c-appr-btn", {
                      ref: "appr-btn",
                      attrs: {
                        flat: "",
                        name: "work-permit-appr-btn",
                        editable: _vm.editable,
                        approvalInfo: _vm.approvalInfo,
                      },
                      on: {
                        beforeApprAction: _vm.approvalPatrol,
                        callbackApprAction: _vm.approvalPatrolCallback,
                        requestAfterAction: _vm.getDetail,
                      },
                    })
                  : _vm._e(),
                _c("c-btn", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.editable && !_vm.disabled,
                      expression: "editable&&!disabled",
                    },
                  ],
                  attrs: {
                    flat: "",
                    url: _vm.saveUrl,
                    isSubmit: _vm.isSave,
                    param: _vm.patrol,
                    mappingType: _vm.saveType,
                    label: "저장",
                    icon: "save",
                  },
                  on: {
                    beforeAction: _vm.savePlan,
                    btnCallback: _vm.saveCallback,
                  },
                }),
                _vm.isOld &&
                !_vm.disabled &&
                _vm.editable &&
                !_vm.popupParam.isApprContent
                  ? _c("c-btn", {
                      attrs: {
                        flat: "",
                        label: "삭제",
                        icon: "delete_forever",
                      },
                      on: { btnClicked: _vm.remove },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "popup-bottom-bar-close" },
          [
            _c("q-btn", {
              attrs: { flat: "", color: "gray", icon: "arrow_back" },
              on: { click: _vm.closePopUps },
            }),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }